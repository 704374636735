import { useState } from "react";
import { Close } from "../../Icons/Icons";

const SideFilter2 = ({ showFilter1, setShowFilter1, onApplyFilter }) => {
    const [scrappedOnFrom, setScrappedOnFrom] = useState('');
    const [scrappedOnTo, setScrappedOnTo] = useState('');
    const [requestedOnFrom, setRequestedOnFrom] = useState('');
    const [requestedOnTo, setRequestedOnTo] = useState('');
    
    const defaultStatus = {
        pending: false,
        scheduled: false,
        in_progress: false,
        completed: false,
        failed: false,
        error: false,
    }
    const [statuses, setStatuses] = useState(defaultStatus);

    const clearFilter = () => {
        setScrappedOnFrom('');
        setScrappedOnTo('');
        setRequestedOnFrom('');
        setRequestedOnTo('');
        setStatuses(defaultStatus);

        onApplyFilter({});
    }
    
    const handleStatusChange = (event) => {
        const { id, checked } = event.target;
        setStatuses((prev) => ({ ...prev, [id]: checked }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // onApplyFilter({
        //     scrappedOnFrom,
        //     scrappedOnTo,
        //     requestedOnFrom,
        //     requestedOnTo,
        //     statuses: Object.keys(statuses).filter(status => statuses[status]),
        // });

        let payload = {

        }

        if (scrappedOnFrom) {
            payload.processed_at = {
                ...payload.processed_at,
                gte: scrappedOnFrom
            }
        }
        if (scrappedOnTo) {
            payload.processed_at = {
                ...payload.processed_at,
                lte: scrappedOnTo
            }
        }

        if (requestedOnFrom) {
            payload.created_at = {
                ...payload.created_at,
                gte: requestedOnFrom
            }
        }
        if (requestedOnTo) {
            payload.created_at = {
                ...payload.created_at,
                lte: requestedOnTo
            }
        }

        if (Object.keys(statuses).filter(status => statuses[status]).length > 0) {
            payload.status = {
                in_list: Object.keys(statuses).filter(status => statuses[status])
            }
        }

        onApplyFilter(payload)
        setShowFilter1(false);
    };

    return (
        <div className={`fixed top-0 left-0 w-screen h-screen z-50 ${showFilter1 ? 'visible' : 'hidden'}`}>
            <div className="absolute bg-gray-800 bg-opacity-25 backdrop-blur w-full h-full" onClick={() => setShowFilter1(false)}></div>
            <div className={`max-w-md w-full h-screen mx-auto bg-white rounded-lg shadow-lg absolute ${showFilter1 ? 'right-0' : '-right-full'} top-0`}>
                <div className="flex items-center justify-between p-5 border-b">
                    <h2 className="text-lg text-inherit font-semibold font-bold">Filters</h2>
                    <Close className='cursor-pointer' onClick={() => setShowFilter1(false)} />
                </div>
                <form className="p-5 flex flex-col justify-between" style={{ height: 'calc(100vh - 70px)' }} onSubmit={handleSubmit}>
                    <div className="max-h-full overflow-auto">
                        {/* Scrapped On */}
                        <p className="block text-gray-900 font-medium text-sm mb-2">Processed On</p>
                        <div className="flex items-start justify-start gap-3 mb-5">
                            <div className="w-full">
                                <label className="block text-gray-900 font-medium text-sm mb-2">From</label>
                                <input type="date" value={scrappedOnFrom} onChange={(e) => setScrappedOnFrom(e.target.value)} className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50" />
                            </div>
                            <div className="w-full">
                                <label className="block text-gray-900 font-medium text-sm mb-2">To</label>
                                <input type="date" value={scrappedOnTo} onChange={(e) => setScrappedOnTo(e.target.value)} className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50" />
                            </div>
                        </div>
                        {/* Requested On */}
                        <p className="block text-gray-900 font-medium text-sm mb-2">Requested On</p>
                        <div className="flex items-start justify-start gap-3 mb-5">
                            <div className="w-full">
                                <label className="block text-gray-900 font-medium text-sm mb-2">From</label>
                                <input type="date" value={requestedOnFrom} onChange={(e) => setRequestedOnFrom(e.target.value)} className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50" />
                            </div>
                            <div className="w-full">
                                <label className="block text-gray-900 font-medium text-sm mb-2">To</label>
                                <input type="date" value={requestedOnTo} onChange={(e) => setRequestedOnTo(e.target.value)} className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50" />
                            </div>
                        </div>
                        {/* Request Status */}
                        <label className="block text-gray-900 font-medium text-sm mb-2">Request Status</label>
                        <div className="relative p-3 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50 mb-5 max-h-60 overflow-auto">
                            {Object.keys(statuses).map((status, index) => (
                                <div className="flex items-center mb-4" key={index}>
                                    <input id={status} type="checkbox" checked={statuses[status]} onChange={handleStatusChange} className="w-4 h-4 text-primary1 bg-gray-100 border-gray-300 rounded" />
                                    <label htmlFor={status} className="ms-2 text-sm font-medium text-gray-900">{status}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center justify-end pt-4 border-t gap-2">
                        <button type="submit" className="bg-primary1 text-white py-2 px-3 rounded-lg w-full">Apply filter</button>
                        <button type="reset" className="bg-white text-gray-900 border border-gray-200 py-2 px-3 rounded-lg w-full" onClick={() => clearFilter()}>Clear all</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SideFilter2;
