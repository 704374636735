import { Sidebar } from "flowbite-react";
import { HiHome, HiPlusCircle, HiOutlineChartBar, HiClipboardList, HiCog } from "react-icons/hi";
import { Logout } from "../../Icons/Icons";
import { useLocation } from "react-router-dom";

function removeCookie(key) {
    console.log('key:', key);
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function Sidemenu({ style }) {
    const {pathname} = useLocation();
    return (
        <Sidebar className="h-screen bg-white whiteSidebar" style={{ style }}>
            <div className="flex items-center justify-center mt-2">
                <img src="./assets/svg/logo.svg" alt="" className="min-w-36"/>
            </div>
            <Sidebar.Items className="mt-5">
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/dashboard" icon={HiHome} className={pathname === '/dashboard' && 'bg-gray-100'}>
                        Home
                    </Sidebar.Item>
                    <Sidebar.Item href="/priority" icon={HiPlusCircle} className={pathname === '/priority' && 'bg-gray-100'}>
                        Priority Order
                    </Sidebar.Item>
                    <div className="flex items-center justify-start absolute left-2 bottom-2" onClick={() => removeCookie('token')}>
                        <Sidebar.Item href="/priority" icon={Logout}>
                            Sign-out
                        </Sidebar.Item>
                    </div>
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    );
}
