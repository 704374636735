import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
import { HiHome, HiBell } from "react-icons/hi";
import { Messages } from "../../Icons/Icons";
import SuperSimple from "../../Components/Dragable/Dragable";
import { useEffect, useState } from "react";
import priority from '../../JSon/priority.json'
import { useDispatch, useSelector } from "react-redux";
import { getPriorityList, priorityData, updatePriorityList } from "../../reducer/PrioritySlice";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Priority = () => {

    const dispatch = useDispatch();
    const notify = (message) => toast(message);

    const { isPriorityFetching, isPrioritySuccess, isPriorityError, priorData, setIsPriorityFetching, setIsPrioritySuccess, setIsPriorityError } = useSelector(priorityData);

    useEffect(() => {
        dispatch(getPriorityList({}));
    }, [dispatch]);

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (priorData?.requests) {
            // Sort the data by your desired criteria, for example, by `priority` field
            const sortedData = [...priorData.requests]
            setItems(sortedData);
        }
    }, [priorData]);

    // Function to update priority based on new order
    const updated_priority_ids = (updatedItems) => {
        return updatedItems.map((item, index) => (item.id));
    };

    // Function to handle priority update on button click
    const handleUpdateOrder = async () => {
        const updatedItems = {
            priority_order: updated_priority_ids(items)
        };
        // setItems(updatedItems);
        console.log('Updated Items:', updatedItems);
        // You can also make an API call here to save the updated items

        try{
            await dispatch(updatePriorityList({updatedItems})).unwrap();
            notify('Order Updated Successfully!');
            dispatch(getPriorityList({}));
        }
        catch(e){
            console.log("ERROR", e);
        }
    };

    return (
        <>
            {
                isPriorityFetching && <Loader />
            }
            <ToastContainer />
            <div className="bg-white px-4 py-4 rounded-lg border-solid border-2 border-grey1 flex items-center justify-between">
                <Breadcrumb aria-label="Default breadcrumb example">
                    <BreadcrumbItem href="/dashboard" icon={HiHome} />
                    <BreadcrumbItem className='text-primary1'>Priority Order</BreadcrumbItem>
                </Breadcrumb>

                {/* <div className="flex items-center justify-end gap-4">
                    <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
                        <Messages />
                        Messages
                    </p>
                    <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
                        <HiBell class="text-secondary1" />
                        Notification
                    </p>
                </div> */}
            </div>

            <div className="bg-white rounded-lg border-solid border-2 border-gray-100 mt-4">
                <p className="text-lg text-gray-900 font-bold text-inter py-4 px-4">Priority Order</p>

                <div class="overflow-x-auto pb-6 border-t">
                    <div class="w-full">
                        <div className="bg-newgrey flex items-center justify-start">
                            <div class="px-4 py-4 text-secondary1 w-20"></div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">Show Name</div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">Code Name</div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">URL</div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">Release Date</div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">ETA</div>
                            <div class="px-4 py-4 flex-1 text-left text-xs font-inter uppercase font-semibold text-secondary1">Status</div>
                        </div>
                        <SuperSimple items={items} setItems={setItems} />
                    </div>
                    <div className="mt-6 text-right px-6">
                        <button type="button" className="bg-primary1 text-white px-4 py-2 rounded-md" onClick={handleUpdateOrder}>Update Order</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Priority