import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
import { HiHome, HiBell } from "react-icons/hi";
import { Messages } from "../../Icons/Icons";
import { useNavigate, useParams } from "react-router-dom";
import reqests from '../../JSon/dashboard.json'
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest } from "../../reducer/dataSlice";
import { getStatusOfReq, reqestData } from "../../reducer/ReqestSlice";
import { useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DetailPage = () => {

    const notify = (message) => toast(message);
    const navigate = useNavigate();

    const { isReqStatusFetching, isReqStatusSuccess, isReqStatusError, reqData } = useSelector(reqestData);
    const { id } = useParams();
    const dispatch = useDispatch();
    { console.log("id:", id) }

    useEffect(() => {
        dispatch(getStatusOfReq({ request_id: id }))
    }, []
    )

    const handleDelete = async () => {
        try {
            await dispatch(deleteRequest(id)).unwrap();
            notify('Request Deleted!');
            navigate('/dashboard')
            // Handle success, e.g., navigate to another page or display a success message
        } catch (err) {
            console.log(err)
            if (err.status === 400) {
                console.error("Bad Request:", err.message);
                notify(err.message)
            }
            else if (err.status === 404) {
                console.error("Not Found:", err.message);
                notify('Request Not Found!');
            } 
            else if (err.status === 422) {
                console.error("Validation Error:", err.message);
            } else {
                console.error("Unexpected Error:", err.message);
            }
        }
    };

    const formattedDate = reqData?.scheduled_at
        ? moment(reqData?.scheduled_at).format('YYYY-MM-DD')
        : '';

    const formattedDate2 = reqData?.release_date_time
        ? moment(reqData.release_date_time).format('YYYY-MM-DD')
        : '';

    const formattedDate3 = reqData?.processed_at
        ? moment(reqData.processed_at).format('YYYY-MM-DD')
        : '';

    return (
        <>
            {
                isReqStatusFetching && <Loader />
            }

            <ToastContainer />

            <div className="bg-white px-4 py-4 rounded-lg border-solid border-2 border-grey1 flex items-center justify-between">
                <Breadcrumb aria-label="Default breadcrumb example">
                    <BreadcrumbItem href="/dashboard" icon={HiHome} />
                    <BreadcrumbItem className='text-primary1'>Request Details</BreadcrumbItem>
                </Breadcrumb>

                {/* <div className="flex items-center justify-end gap-4">
                    <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
                        <Messages />
                        Messages
                    </p>
                    <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
                        <HiBell class="text-secondary1" />
                        Notification
                    </p>
                </div> */}
            </div>


            <div className="w-full mt-4 bg-white p-8 rounded-lg border-grey1 border-2 border-solid">
                <h2 className="text-lg font-inter font-bold mb-6 flex items-center justify-start gap-2">Suits - S1E4 <span className={`${(reqData?.status === 'scheduled' || reqData?.status === 'in progress') && "bg-orange-100 text-orange-500"} ${reqData?.status === 'requested' && "text-darkBlue bg-lightBlue"} ${reqData?.status === 'completed' && "text-green-500 bg-green-100"} ${reqData?.status === 'denied' && "text-red-500 bg-red-100"} py-1 px-2 text-xs rounded-md capitalize`}>{reqData?.status}</span></h2>
                <form>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pb-6 border-b">
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Client Content Id</label>
                            <input disabled value={reqData?.client_content_id} type="text" placeholder="client-content-id" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Content Name</label>
                            <input disabled value={reqData?.content_name} type="text" placeholder="Suits" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Episode Name</label>
                            <input disabled value={reqData?.episode_name} type="text" placeholder="One With the..." className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Season Number</label>
                            <input disabled value={reqData?.season_number} type="text" placeholder="Season 1" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Episode Number</label>
                            <input disabled value={reqData?.episode_number} type="text" placeholder="Episode 5" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Length</label>
                            <input disabled value={reqData?.length_minutes} type="text" placeholder="24 Minutes" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>
                        {
                            reqData?.scheduled_at &&
                            <div>
                                <label className="block text-gray-900 font-semibold mb-2">Scheduled At</label>
                                <input disabled value={formattedDate} type="date" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                            </div>
                        }
                        <div>
                            <label className="block text-gray-900 font-semibold mb-2">Release Date</label>
                            <input disabled value={formattedDate2} type="date" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                        </div>

                    </div>

                    {
                        (reqData?.processed_at || reqData?.dt_known_id) &&
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pt-5">
                            {
                                reqData?.processed_at &&
                                (<div>
                                    <label className="block text-gray-900 font-semibold mb-2">Processed At</label>
                                    <input disabled value={formattedDate3} type="date" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                                </div>)
                            }
                            {
                                reqData?.dt_known_id &&
                                (<div>
                                    <label className="block text-gray-900 font-semibold mb-2">Dt Known Id</label>
                                    <input disabled value={reqData?.dt_known_id} type="text" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3.5 bg-gray-50" />
                                </div>)
                            }
                        </div>
                    }

                    <p className="text-md font-inter font-bold mt-6">URLs</p>
                    <ul className="mt-2 list-disc pl-5">
                        <li className="text-blue cursor-pointer inline" onClick={() => window.open(reqData?.urls, '_blank')}>{reqData?.urls}</li>
                    </ul>
                    <div className="mt-6 text-right">
                        <button disabled={reqData?.status == "completed" || reqData?.status == "in_progress"} type="button" className={`text-white px-4 py-2 rounded-md ${reqData?.status == "completed" || reqData?.status == "in_progress" ? 'bg-gray-200' : 'bg-red-800'}`} onClick={handleDelete}>Delete Request</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default DetailPage