import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";
import { getCookie } from "./utils";

const baseURL = process.env.REACT_APP_BASE_URL;

export const getEvents = createAsyncThunk(
    "/api/v2/requests",
    async ({ formData }, thunkAPI) => {  // Accept form data and token
        try {
            console.log('test')
            const response = await fetch(
                `${baseURL}/api/v2/requests`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`  // Include the token in the headers
                    },
                    body: JSON.stringify(formData),  // Pass form data in the request body
                }
            );

            let data = await response.json();
            // Handle different response statuses
            if (response.status === 201) {  // Successful creation
                return data?.Data;
            } else if (response.status === 400) {  // Bad request
                return thunkAPI.rejectWithValue({
                    status: 400,
                    message: "Bad Request: The server could not understand the request due to invalid syntax.",
                    data
                });
            } else if (response.status === 422) {  // Validation error
                return thunkAPI.rejectWithValue({
                    status: 422,
                    message: "Validation Error: The request was well-formed but contains semantic errors.",
                    data
                });
            } else {  // Other errors
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    message: "An unexpected error occurred.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);

export const getPriorityList = createAsyncThunk(
    "/api/v2/requests/priority/order",
    async (thunkAPI) => {
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/priority/order`,
                requests,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                return data?.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.log("ERROR", e);
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getStatusOfReq = createAsyncThunk(
    "/api/v2/requests",
    async ({ formData, request_id }, thunkAPI) => {  // Accept form data and token
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/${request_id}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlkIjoiMTNjMzA5N2ItZDU5ZC00YzNhLTllZTUtYjM4YWM4NGEzMWY5IiwiaWF0IjoxNzIwNzYzMzkwLCJleHAiOjE3MjEzNjgxOTB9.phn_jBpTyY2JOT8e9M4Ggy5l28LXCsVEE21MDfrbHck`  // Include the token in the headers
                    },
                }
            );

            let data = await response.json();
            // Handle different response statuses
            if (response.status === 200) {  // Successful creation
                return data?.Data;
            } else if (response.status === 404) {  // Bad request
                return thunkAPI.rejectWithValue({
                    status: 404,
                    message: "Bad Request: The server could not understand the request due to invalid syntax.",
                    data
                });
            } else if (response.status === 422) {  // Validation error
                return thunkAPI.rejectWithValue({
                    status: 422,
                    message: "Validation Error: The request was well-formed but contains semantic errors.",
                    data
                });
            } else {  // Other errors
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    message: "An unexpected error occurred.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);


export const deleteRequest = createAsyncThunk(
    '/api/v2/requests/{id}',
    async (id, thunkAPI) => {  // Accept the ID of the request to be deleted
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`,  // Include the token in the headers
                    },
                }
            );

            // Handle different response statuses
            if (response.status === 204) {  // Successful deletion
                return {};  // No content expected
            }
            else if (response.status === 400) {  // Not found
                return thunkAPI.rejectWithValue({
                    status: 400,
                    message: "Cant delete: The requested is processing or completed.",
                });
            }
            else if (response.status === 404) {  // Not found
                return thunkAPI.rejectWithValue({
                    status: 404,
                    message: "Not Found: The requested resource could not be found.",
                });
            } else if (response.status === 422) {  // Validation error
                const data = await response.json();
                return thunkAPI.rejectWithValue({
                    status: 422,
                    message: "Validation Error: The request was well-formed but contains semantic errors.",
                    data
                });
            } else {  // Other errors
                const data = await response.json();
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    message: "An unexpected error occurred.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);

// bull delete api
export const deleteBulkRequest = createAsyncThunk(
    "/api/v2/requests/delete/bulk",
    async (request_ids, thunkAPI) => {  // Accept request_ids to be deleted
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/delete/bulk`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ request_ids: request_ids })  // Pass request_ids in the request body
                }
            );

            if (response.status === 204) {  // Successful deletion
                return {};
            }
            else if (response.status === 400) {  // Not found
                return thunkAPI.rejectWithValue({
                    status: 400,
                    message: "Cant delete: The requested is processing or completed.",
                });
            }
            else if (response.status === 404) {  // Not found
                return thunkAPI.rejectWithValue({
                    status: 404,
                    message: "Not Found: The requested resource could not be found.",
                });
            } else if (response.status === 422) {  // Validation error
                const data = await response.json();
                return thunkAPI.rejectWithValue({
                    status: 422,
                    message: "Validation Error: The request was well-formed but contains semantic errors.",
                    data
                });
            } else {  // Other errors
                const data = await response.json();
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    message: "An unexpected error occurred.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);



export const eventSlice = createSlice({
    name: "requests",
    initialState: {
        isReqFetching: false,
        isReqSuccess: false,
        isReqError: false,
        reqErrorMessage: '',
        requests: [],
        success: ''
    },
    reducers: {
        clearState: (state) => {
            state.isReqFetching = false
            state.isReqSuccess = false
            state.isReqError = false
            state.ReqErrorMessage = ''
            return state;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getPriorityList.pending, (state) => {
            state.isReqFetching = true
        });

        builder.addCase(getPriorityList.fulfilled, (state, { payload }) => {
            state.isReqFetching = false
            state.isReqSuccess = true
            state.success = payload?.status ?? "Success!";
        });

        builder.addCase(getPriorityList.rejected, (state, { payload }) => {
            state.isReqError = false
            state.isReqFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

    },
});

export const prioritySlice = createSlice({
    name: "priority",
    initialState: {
        isPriorityFetching: false,
        isPrioritySuccess: false,
        isPriorityError: false,
        ReqErrorMessage: ''
    },
    reducers: {
        clearPriorityState: (state) => {
            state.isPriorityFetching = false
            state.isPrioritySuccess = false
            state.isPriorityError = false
            return state;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getEvents.pending, (state) => {
            state.isPriorityFetching = true
        });

        builder.addCase(getEvents.fulfilled, (state, { payload }) => {
            state.isPriorityFetching = false
            state.isPrioritySuccess = true
        });

        builder.addCase(getEvents.rejected, (state, { payload }) => {
            state.isPriorityError = false
            state.isPriorityFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

    },
});

export const StatusReqSlice = createSlice({
    name: "statusOfReqest",
    initialState: {
        isReqStatusFetching: false,
        isReqStatusSuccess: false,
        isReqStatusError: false,
        ReqErrorMessage: '',
        isDeleteFetching: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        deleteErrorMessage: '',
    },
    reducers: {
        clearStatusState: (state) => {
            state.isReqStatusFetching = false;
            state.isReqStatusSuccess = false;
            state.isReqStatusError = false;
            return state;
        }
    },
    extraReducers: (builder) => {
        // Existing handlers for getStatusOfReq
        builder.addCase(getStatusOfReq.pending, (state) => {
            state.isReqStatusFetching = true;
        });

        builder.addCase(getStatusOfReq.fulfilled, (state, { payload }) => {
            state.isReqStatusFetching = false;
            state.isReqStatusSuccess = true;
        });

        builder.addCase(getStatusOfReq.rejected, (state, { payload }) => {
            state.isReqStatusError = true;
            state.isReqStatusFetching = false;
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

        // Handlers for deleteRequest
        builder.addCase(deleteRequest.pending, (state) => {
            state.isDeleteFetching = true;
        });

        builder.addCase(deleteRequest.fulfilled, (state) => {
            state.isDeleteFetching = false;
            state.isDeleteSuccess = true;
        });

        builder.addCase(deleteRequest.rejected, (state, { payload }) => {
            state.isDeleteError = true;
            state.isDeleteFetching = false;
            state.deleteErrorMessage = payload?.message ?? "Something went wrong!";
        });

        builder.addCase(deleteBulkRequest.pending, (state) => {
            state.isDeleteFetching = true;
        });

        builder.addCase(deleteBulkRequest.fulfilled, (state) => {
            state.isDeleteFetching = false;
            state.isDeleteSuccess = true;
        });

        builder.addCase(deleteBulkRequest.rejected, (state, { payload }) => {
            state.isDeleteError = true;
            state.isDeleteFetching = false;
            state.deleteErrorMessage = payload?.message ?? "Something went wrong!";
        });
    },
});

export const { clearState } = eventSlice.actions;
export const { clearPriorityState } = prioritySlice.actions;
export const { clearStatusState } = StatusReqSlice.actions;

export const requestsData = (state) => state.requestsData;
export const priorityData = (state) => state.priorityData;
export const selectPriorityData = (state) => state.priorityData;