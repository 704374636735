import React, { useState } from 'react';
import { List, arrayMove } from 'react-movable';
import { useSelector } from 'react-redux';
import { priorityData } from '../../reducer/PrioritySlice';

const SuperSimple = ({ items, setItems }) => {

  const [drag, setDrag] = useState([null]);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  return (
    <List
      values={items}
      onChange={({ oldIndex, newIndex }) =>
        setItems(arrayMove(items, oldIndex, newIndex))
      }
      renderList={({ children, props }) => (
        <div {...props} className='relative'>
          {children}
        </div>
      )}
      renderItem={({ value, props, index }) => (
        <div className={`border-b w-full max-w-full flex items-center justify-start ${index === 0 ? 'border-t' : ''}`} {...props} onClick={() => setDrag(index + 1)}>
          <div className="px-4 py-4 flex items-center justify-center text-black font-bold w-20">
            <img src="./assets/svg/bars-outline.svg" alt="" />
          </div>
          <div className="px-4 py-4 text-sm font-inter flex-1 text-black font-bold">
            {value.content_name}
          </div>
          <div className="px-4 py-4 text-sm font-inter flex-1 text-secondary1 font-bold">
            {value.content_name}
          </div>
          <div className="px-4 py-4 text-sm font-inter flex-1 text-secondary1 font-bold">
            {value.urls}
          </div>
          <div className="px-4 py-4 text-sm font-inter flex-1 text-secondary1 font-bold">
            {formatDate(value.release_date_time)}
          </div>
          <div className="px-4 py-4 text-sm font-inter flex-1 text-secondary1 font-bold">
            {formatDate(value.scheduled_at)}
          </div>
          <div className="px-4 py-4 flex-1 text-secondary1 font-bold">
            <span className={`${value.status === 'scheduled' && "bg-orange-100 text-orange-500"} ${value.status === 'pending' && "text-darkBlue bg-lightBlue"} ${value.status === 'completed' && "text-green-500 bg-green-100"} py-1 px-2 text-xs rounded-md capitalize`}>
              {value.status}
            </span>
          </div>
        </div>
      )}
    />
  );
};

export default SuperSimple;
