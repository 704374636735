import { useEffect, useState } from "react"
import {useDispatch, useSelector} from 'react-redux'
import { Authentication, authData } from "../../reducer/Auth"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const Login = ({tabs, setTabs}) => {
    const notify = (message) => toast(message);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthFetching, isAuthSuccess, isAuthError, loginData } = useSelector(authData);

    function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      
        return JSON.parse(jsonPayload);
      }
      
    function getTokenExpiry(token) {
        const decoded = parseJwt(token);
        return decoded.exp ? new Date(decoded.exp * 1000) : null;
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        console.log('Login form submitted')
        console.log('Email:', email, 'Password:', password)

        const formData = {
            email: email,
            password: password
        }
        try{
            await dispatch(Authentication({formData})).unwrap();
            notify('Login Successful!');

            navigate('/dashboard');
        }
        catch(e){
            console.log("ERROR", e);
        }
    }

    useEffect(() => {
        if(isAuthSuccess){
            console.log('Login Successful:', loginData)
            const expiry = getTokenExpiry(loginData.token);
            // set loginData to session storage with expiry
            document.cookie = `token=${loginData.token}; expires=${expiry}; path=/`;
        }
        if(isAuthError){
            console.log('Login Error:', isAuthError)
        }
    }, [isAuthSuccess, isAuthError])

    return (
        <>
        {
            isAuthFetching && <Loader />
        }
            <ToastContainer />
            <img src="./assets/svg/logo.svg" alt="" />
            <h2 class="text-2xl font-bold mt-5 text-secondary2">Welcome back</h2>
            <form class="mt-6" >
                <div className="flex items-center justify-start gap-4">
                    <div className="w-full">
                        <label class="block text-sm font-medium text-secondary1">Email</label>
                        <input type="email" placeholder="name@example.com" class="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary1 focus:border-transparent" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="w-full">
                        <label class="block text-sm font-medium text-secondary1">Password</label>
                        <input type="password" placeholder="********" class="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary1 focus:border-transparent" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
            </form>
            {/* <div class="relative my-6">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center text-sm">
                    <span class="px-2 text-gray-600 bg-white">or</span>
                </div>
            </div> */}
            {/* <button class="w-full flex items-center justify-center px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none">
                <img src="./assets/img/google.png" alt="Google" class="w-5 h-5 mr-2" />
                Sign up with Google
            </button> */}
            {/* <div class="flex items-center justify-between mt-4">
                <div class="flex items-center">
                    <input id="remember_me" type="checkbox" class="w-4 h-4 text-primary1 border-gray-300 rounded focus:ring-primary1" />
                    <label for="remember_me" class="ml-2 text-sm text-gray-700">Remember me</label>
                </div>
                <p onClick={()=>setTabs(2)} class="text-sm text-blue hover:underline">Forgot password?</p>
            </div> */}
            <button onClick={handleLogin} type="submit" class="w-full mt-6 px-4 py-2 text-white bg-primary1 rounded-md hover:bg-primary1 focus:outline-none focus:bg-primary1">
                Sign in
            </button>
        </>
    )
}

export default Login