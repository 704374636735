import { useState } from "react"
import Login from "./Login"
import Forget from "./Forget"
import ResetPass from "./ResetPassword"

const Auth = () => {

  const [tabs, setTabs] = useState(1)

  return (
    <div className="container mx-auto py-6 px-4 flex items-center justify-between gap-20" style={{height: 'calc(100vh - 160px)'}}>
      <div class="w-full max-w-xl p-8 bg-white rounded-lg shadow-md border-solid border">
        {
          tabs === 1 && <Login tabs={tabs} setTabs={setTabs}/> 
          // tabs === 2 && <Forget tabs={tabs} setTabs={setTabs}/> ||
          // tabs === 3 && <ResetPass tabs={tabs} setTabs={setTabs}/>
        }
      </div>
      <div>
        <img className="w-full max-w-xl" src="./assets/img/loginVector.png" alt="hero" />
      </div>
    </div>
  )
}

export default Auth