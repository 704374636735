import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const baseURL = process.env.REACT_APP_BASE_URL;

export const Authentication = createAsyncThunk(
    "/api/v2/auth/login",
    async ({formData}, thunkAPI) => {  // Accept form data and token
        try {
            console.log('hey', formData)
            const response = await fetch(
                `${baseURL}/api/v2/auth/login`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),  
                }
            );
            let data = await response.json();
            // Handle different response statuses
            if (response.status === 200) {  // Successful creation
                return data;
            } else if (response.status === 400) {  // Bad request
                return thunkAPI.rejectWithValue({
                    status: 400,
                    message: "Bad Request: The server could not understand the request due to invalid syntax.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);

export const AuthSlice = createSlice({
    name: "AuthRequests",
    initialState: {
        isAuthFetching: false,
        isAuthSuccess: false,
        isAuthError: false,
        loginData: null,
        success: ''
    },
    reducers: {
        clearState: (state) => {
            state.isAuthFetching = false
            state.isAuthSuccess = false
            state.isAuthError = false
            state.ReqErrorMessage = ''
            return state;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(Authentication.pending, (state) => {
            state.isAuthFetching = true
        });

        builder.addCase(Authentication.fulfilled, (state, { payload }) => {
            state.isAuthFetching = false
            state.isAuthSuccess = true
            state.loginData = payload;
            state.success = payload?.status ?? "Success!";
            console.log('payload:',payload)
        });

        builder.addCase(Authentication.rejected, (state, { payload }) => {
            state.isAuthError = false
            state.isAuthFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
            console.log(payload)
        });

    },
});

export const { clearState } = AuthSlice.actions;

export const authData = (state) => state.authData;