import { configureStore } from "@reduxjs/toolkit";
import { eventSlice } from '../dataSlice';
import {  bulkStatusSlice } from "../BulkSlice";
import { StatusReqSlice } from "../ReqestSlice";
import { prioritySlice } from "../PrioritySlice";
import { AuthSlice } from "../Auth";

export default configureStore({
    reducer: {
        requestsData: eventSlice.reducer,
        priorityData: prioritySlice.reducer,
        bulkReqData: bulkStatusSlice.reducer,
        reqestData: StatusReqSlice.reducer,
        priorityData: prioritySlice.reducer,
        authData: AuthSlice.reducer,

    },
});