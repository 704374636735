import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie } from "./utils";

const baseURL = process.env.REACT_APP_BASE_URL;

export const getStatusOfReq = createAsyncThunk(
    "/api/v2/requests",
    async ({ request_id }, thunkAPI) => {  // Accept form data and token
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/${request_id}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`  // Include the token in the headers
                    },
                }
            );

            let data = await response.json();
            // Handle different response statuses
            if (response.status === 200) {  // Successful creation
                return data;
            } else if (response.status === 404) {  // Bad request
                return thunkAPI.rejectWithValue({
                    status: 404,
                    message: "Bad Request: The server could not understand the request due to invalid syntax.",
                    data
                });
            } else if (response.status === 422) {  // Validation error
                return thunkAPI.rejectWithValue({
                    status: 422,
                    message: "Validation Error: The request was well-formed but contains semantic errors.",
                    data
                });
            } else {  // Other errors
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    message: "An unexpected error occurred.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);

export const deleteAdsById = createAsyncThunk(
    "/api/v2/requests/",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${baseURL}/api/v2/requests/${request_id}`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`  // Include the token in the headers
                    },
                }
            );
            let data = await response.json();
            if (response.status === 204) {
                return ('');
            } else if (response.status === 404) {
                return thunkAPI.rejectWithValue({ error: 'Not Found' });
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log(error, "err");
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const StatusReqSlice = createSlice({
    name: "statusOfReqest",
    initialState: {
        isReqStatusFetching: false,
        isReqStatusSuccess: false,
        isReqStatusError: false,

        isDeleteStatusFetching: false,
        isDeleteStatusSuccess: false,
        isDeleteStatusError: false,

        ReqErrorMessage: '',
        reqData: [],
    },
    reducers: {
        clearState: (state) => {
            state.isReqStatusFetching = false;
            state.isReqStatusSuccess = false;
            state.isReqStatusError = false;

            state.isDeleteStatusFetching = false;
            state.isDeleteStatusSuccess = false;
            state.isDeleteStatusError = false;
            return state;
        }
    },
    extraReducers: (builder) => {
        // Existing handlers for getStatusOfReq
        builder.addCase(getStatusOfReq.pending, (state) => {
            state.isReqStatusFetching = true;
        });

        builder.addCase(getStatusOfReq.fulfilled, (state, { payload }) => {
            state.isReqStatusFetching = false;
            state.isReqStatusSuccess = true;
            state.reqData = payload;
            state.success = payload?.status ?? "Success!";
            console.log('payload:', payload)
        });

        builder.addCase(getStatusOfReq.rejected, (state, { payload }) => {
            state.isReqStatusError = true;
            state.isReqStatusFetching = false;
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

        builder.addCase(deleteAdsById.fulfilled, (state, { payload }) => {
            state.isDeleteStatusFetching = false;
            state.isDeleteStatusSuccess = true;
            state.isDeleteStatusError =
                payload.Message || "Request Deleted Successfully";
            return state;
        });
        builder.addCase(deleteAdsById.rejected, (state, { payload }) => {
            state.isDeleteStatusFetching = false;
            state.isDeleteStatusSuccess = true;
            state.isDeleteStatusError = payload?.Message || "Something Went Wrong";
            return state;
        });
        builder.addCase(deleteAdsById.pending, (state, { payload }) => {
            state.isDeleteStatusFetching = true;
            return state;
        });
    },
});

export const { clearState } = StatusReqSlice.actions;

export const reqestData = (state) => state.reqestData;