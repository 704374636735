import ImgLoader from './loader.gif'

const Loader = () => {
  return (
    <div className='w-full h-full fixed z-50 backdrop-blur-sm left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-bgLoader'>
        <img src={ImgLoader} alt="" />
    </div>
  )
}

export default Loader