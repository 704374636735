import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from "flowbite-react";
import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
import { HiHome, HiOutlineEye, HiOutlineDownload, HiFilter, HiPlus, HiTrash, HiBell } from "react-icons/hi";
import { TablePagination } from "../../Components/Pagination/Pagination";
import { Messages } from "../../Icons/Icons";
import SideFilter from "../../Components/SideFilters/SideFilter";
import { useEffect, useRef, useState } from "react";
import SideFilter2 from "../../Components/SideFilters/SideFilter2";
import reqests from '../../JSon/dashboard.json'
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { bulkReqData, getBulkStatus } from "../../reducer/BulkSlice";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteBulkRequest } from "../../reducer/dataSlice";

function Dashboard() {

  const notify = (message) => toast(message);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(0);
  const [page_size, setPageSize] = useState(50);

  const [deleteRequests, setDeleteRequests] = useState([]);

  const [filter, setFilter] = useState({
    processed_at: {

    },
    created_at: {

    },
    status: {

    }
  });
  const { isBulkReqFetching, isBulkReqSuccess, isBulkReqError, bulkData, pagination_metadata } = useSelector(bulkReqData);

  const [showFilter, setShowFilter] = useState(false);
  const [showFilter1, setShowFilter1] = useState(false);

  useEffect(() => {
    dispatch(getBulkStatus({
      "pagination_metadata": {
        "start": pageNo,
        "page_size": page_size
      },
      ...filter
    }));
  }, [pageNo]);

  useEffect(() => {
    dispatch(getBulkStatus({
      "pagination_metadata": {
        "start": 0,
        "page_size": page_size
      },
      ...filter
    }));
  }, [filter]);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
  }

  const handleViewClick = (id) => {
    navigate(`/detail/${id}`);
  };

  const toastId = useRef(null);

  const handleBulkDeleteSelect = (e) => {
    if (e.target.checked) {
      setDeleteRequests([...deleteRequests, e.target.name]);
    } else {
      setDeleteRequests(deleteRequests.filter((item) => item !== e.target.name));
    }
  }

  const handleBulkDelete = async () => {
    try {

      toastId.current = toast("Deleting Requests...", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      await dispatch(deleteBulkRequest(deleteRequests)).unwrap();
      
      toast.update(toastId.current, {
        render: "Requests Deleted!",
        type: "success",
        autoClose: 5000,
      });


      
      dispatch(getBulkStatus({
        "pagination_metadata": {
          "start": 0,
          "page_size": page_size
        },
        ...filter
      }));

      // Handle success, e.g., navigate to another page or display a success message
    } catch (err) {
      console.log(err)
      if (err.status === 400) {
        console.error("Bad Request:", err.message);
        notify(err.message)
      }
      else if (err.status === 404) {
        console.error("Not Found:", err.message);
        notify('Request Not Found!');
      }
      else if (err.status === 422) {
        console.error("Validation Error:", err.message);
      } else {
        console.error("Unexpected Error:", err.message);
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <SideFilter notify={notify} showFilter={showFilter} setShowFilter={setShowFilter} />
      <SideFilter2 showFilter1={showFilter1} setShowFilter1={setShowFilter1} onApplyFilter={setFilter} />

      {
        isBulkReqFetching && <Loader />
      }

      <div className="bg-white px-4 py-4 rounded-lg border-solid border-2 border-grey1 flex items-center justify-between">
        <Breadcrumb aria-label="Default breadcrumb example">
          <BreadcrumbItem href="/dashboard" icon={HiHome} />
          <BreadcrumbItem className='text-primary1'>Home</BreadcrumbItem>
        </Breadcrumb>

        {/* <div className="flex items-center justify-end gap-4">
          <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
            <Messages />
            Messages
          </p>
          <p className="text-secondary1 flex items-center justify-start gap-2 text-sm">
            <HiBell class="text-secondary1" />
            Notification
          </p>
        </div> */}
      </div>

      {/* <Accordion className="mt-4 bg-white">
        <AccordionPanel>
          <AccordionTitle className="bg-white text-lg">Client info</AccordionTitle>
          <AccordionContent>
            <div class="grid gap-x-8 gap-y-6 grid-cols-4">

              <div>
                <p class="text-gray-500">Client ID:</p>
                <p class="font-bold text-black">PMSGYNMGUM</p>
              </div>

              <div>
                <p class="text-gray-500">Total Allocated Hours:</p>
                <p class="font-bold text-black">500</p>
              </div>

              <div>
                <p class="text-gray-500">Used Hours:</p>
                <p class="font-bold text-black">395</p>
              </div>

              <div>
                <p class="text-gray-500">Available Hours:</p>
                <p class="font-bold text-black">105</p>
              </div>

              <div>
                <p class="text-gray-500">No. of Requests:</p>
                <p class="font-bold text-black">101</p>
              </div>

              <div>
                <p class="text-gray-500">Completed Requests:</p>
                <p class="font-bold text-black">93</p>
              </div>

              <div>
                <p class="text-gray-500">Scheduled Requests:</p>
                <p class="font-bold text-black">8</p>
              </div>

              <div>
                <p class="text-gray-500">Denied Requests:</p>
                <p class="font-bold text-black">0</p>
              </div>
            </div>

            <div class="mt-8">
              <a href="#" class="text-blue-500 text-blue text-md">Upgrade Plan</a>
            </div>

          </AccordionContent>
        </AccordionPanel>
      </Accordion> */}

      <div class="p-6 bg-white rounded-lg shadow-md mt-4">

        <div class="flex justify-between items-center mb-4">
          <h2 class="text-lg font-semibold">Requests</h2>
          <div class="flex space-x-2">

            <button class="flex items-center border rounded-md px-4 py-2 gap-2 bg-primary1 text-white" onClick={() => setShowFilter(true)}>
              <HiPlus />
              <span className="mr-2">Add Request</span>
            </button>

            {
              deleteRequests.length > 0 && (
                <button class="flex items-center border rounded-md px-4 py-2 gap-2" onClick={() => handleBulkDelete()}>
                  <HiTrash />
                  <span className="mr-2">Bulk Delete</span>
                </button>
              )
            }

            <button class="flex items-center border rounded-md px-4 py-2 gap-2" onClick={() => setShowFilter1(true)}>
              <HiFilter />
              <span class="mr-2">Filter</span>
            </button>

          </div>
        </div>


        <div class="overflow-x-auto border rounded-2xl">
          <table class="min-w-full">
            <thead className="bg-newgrey">
              <tr>
                <th class="px-4 py-4 text-left text-secondary1"></th>
                <th class="px-4 py-4 text-left text-secondary1">Show Name</th>
                <th class="px-4 py-4 text-left text-secondary1">Season No.</th>
                <th class="px-4 py-4 text-left text-secondary1">Episode No.</th>
                <th class="px-4 py-4 text-left text-secondary1">Episode Name</th>
                <th class="px-4 py-4 text-left text-secondary1">URL</th>
                {/* <th class="px-4 py-4 text-left text-secondary1">Release Date</th> */}
                <th class="px-4 py-4 text-left text-secondary1">Processed at</th>
                {/* <th class="px-4 py-4 text-left text-secondary1">ETA</th> */}
                <th class="px-4 py-4 text-left text-secondary1">Status</th>
                <th class="px-4 py-4 text-left text-secondary1">Length(min)</th>
                <th class="px-4 py-4 text-center text-secondary1">View</th>
              </tr>
            </thead>
            <tbody class="text-gray-700">
              {
                bulkData?.map((value, index) => (
                  <tr className={`border-b ${index === 0 ? 'border-t' : ''}`} key={value.id}>

                    <td className="px-4 py-4 text-black font-bold">
                      <input
                        name={value.id}
                        type="checkbox"
                        disabled={value?.status == "completed" || value?.status == "in_progress"}
                        onChange={handleBulkDeleteSelect}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>

                    <td className="px-4 py-4 text-black font-bold">{value.content_name}</td>
                    <td className="px-4 py-4 text-black font-bold">{value.season_number}</td>
                    <td className="px-4 py-4 text-black font-bold">{value.episode_number}</td>
                    <td className="px-4 py-4 text-secondary1 font-bold">{value.episode_name}</td>
                    <td className="px-4 py-4 text-secondary1 font-bold">
                      {
                        value.urls && value.urls.length > 0 && (
                          !value.urls[0].includes('tvstation') && !value.urls[0].includes('flextv')
                        ) && value.urls.map((url, index) => (
                          <a href={value.urls[0]} target="_blank" rel="noopener noreferrer" className="text-blue">
                            {value.urls[0].length > 30 ? `${value.urls[0].substring(0, 30)}...` : value.urls[0]}
                          </a>
                        ))
                      }
                    </td>
                    {/* <td className="px-4 py-4 text-secondary1 font-bold">
                      {formatDate(value.release_date_time)}
                    </td> */}
                    <td className="px-4 py-4 text-secondary1 font-bold">
                      {formatDate(value.processed_at)}
                    </td>
                    {/* <td className="px-4 py-4 text-secondary1 font-bold">
                      {formatDate(value.scheduled_at)}
                    </td> */}
                    <td className="px-4 py-4 text-secondary1 font-bold">
                      <span className={`${(value.status === 'scheduled' || value.status === 'in_progress') && "bg-orange-100 text-orange-500"} ${value.status === 'pending' && "text-darkBlue bg-lightBlue"} ${value.status === 'completed' && "text-green-500 bg-green-100"} ${value.status === 'failed' && "text-red-500 bg-red-100"} px-2 py-1 rounded-md text-sm capitalize`}>{value.status}</span>
                    </td>
                    <td className="px-4 py-4 text-secondary1 font-bold">{value.length_minutes}</td>
                    <td className="px-4 py-4 text-secondary1 font-bold flex justify-center space-x-2">
                      <button className="text-gray-500 hover:text-black">
                        <HiOutlineEye className="w-5 h-5" onClick={() => handleViewClick(value.id)} />
                      </button>
                      {/* <button className="text-gray-500 hover:text-black">
                        <HiOutlineDownload className="w-5 h-5" />
                      </button> */}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <TablePagination pageNo={pageNo} setPageNo={setPageNo} page_size={page_size} start={pagination_metadata?.start} has_more={pagination_metadata?.has_more} />
        </div>
      </div>

    </>
  )
}

export default Dashboard
