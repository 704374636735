import { useEffect, useState } from "react";
import { Close } from "../../Icons/Icons";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useDispatch, useSelector } from 'react-redux';
import { getEvents, requestsData } from "../../reducer/dataSlice";

const SideFilter = ({ showFilter, setShowFilter, notify }) => {

    const [isSeries, setIsSeries] = useState(false);

    const [formData, setFormData] = useState({
        client_content_id: '',
        content_name: '',
        provider: '',
        season_number: null,
        episode_number: null,
        is_series: false,
        library_names: [],
    });

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const { loading } = useSelector(requestsData);

    const validateForm = () => {
        const errors = {};

        if (!formData.client_content_id.trim()) {
            errors.CliCon = "Client Content Id is required";
        }
        if (!formData.content_name.trim()) {
            errors.content_name = "Content Name is required";
        }
        if (!formData.provider.trim() || formData.provider === "Select") {
            errors.provider = "Client Content Id is required";
        }

        if (formData.is_series) {
            if (!formData.season_number.trim() || formData.season_number === "Select") {
                errors.season_number = "Season Number is required";
            }
            if (!formData.episode_number.trim() || formData.episode_number === "Select") {
                errors.episode_number = "Episode Number is required";
            }
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            console.error("Validation errors:", validationErrors);
            return;
        }

        try {
            await dispatch(getEvents({ formData })).unwrap();
            notify('Request Created Successfully!');
            setShowFilter(false);
            // Handle success, e.g., navigate to another page or display a success message
        } catch (err) {
            if (err.status === 400) {
                console.error("Bad Request:", err.message);
            } else if (err.status === 422) {
                console.error("Validation Error:", err.message);
            } else {
                console.error("Unexpected Error:", err.message);
            }
        }
    };

    const handleChange = (e) => {
        console.log('isSeries:', e.target.value, e.target.name)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        console.log('formData:', formData)
    };

    const handleListInputChange = (e) => {
        setFormData({
            ...formData,
            // trim after splitting to remove any extra spaces
            library_names: e.target.value.split(',').map(item => item.trim())
        });
    };

    useEffect(() => {
        console.log('formData:', formData)
    }, [formData]);

    const handleChangeCheckbox = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        });
    };

    return (
        <div className={`fixed top-0 left-0 w-screen h-screen z-50 ${showFilter ? 'visible' : 'hidden'}`}>
            <div className="absolute bg-gray-800 bg-opacity-25 backdrop-blur w-full h-full" onClick={() => setShowFilter(false)}></div>
            <div className={`max-w-md w-full h-screen mx-auto bg-white rounded-lg shadow-lg absolute ${showFilter ? 'right-0' : '-right-full'} top-0`}>
                <div className="flex items-center justify-between p-5 border-b">
                    <h2 className="text-lg text-inherit font-semibold">Create Request</h2>
                    <Close className='cursor-pointer' onClick={() => setShowFilter(false)} />
                </div>
                <form className="p-5 flex flex-col justify-between" style={{ height: 'calc(100vh - 70px)' }} onSubmit={handleSubmit}>
                    <div className="max-h-full overflow-auto">
                        <div className="mb-2.5">
                            <label className="block text-gray-900 font-medium text-sm mb-2">Client Content Id</label>
                            <input
                                type="text"
                                placeholder="client-content-id"
                                className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                value={formData.client_content_id}
                                name="client_content_id"
                                onChange={handleChange}
                            />
                            {errors.client_content_id && <p className="text-red-600 text-sm">{errors.client_content_id}</p>}
                        </div>
                        <div className="mb-2.5">
                            <label className="block text-gray-900 font-medium text-sm mb-2">Content Name</label>
                            <input
                                type="text"
                                placeholder="content Name"
                                className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                value={formData.content_name}
                                name="content_name"
                                onChange={handleChange}
                            />
                            {errors.content_name && <p className="text-red-600 text-sm">{errors.content_name}</p>}
                        </div>
                        <div className="mb-2.5">
                            <label className="block text-gray-900 font-medium text-sm mb-2">Provider</label>
                            <select id="client-request-id" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                value={formData.provider}
                                onChange={handleChange}
                                name="provider"
                            >
                                <option>Select</option>
                                <option value="netflix">netflix</option>
                                <option value="youtube_tv">youtube_tv</option>
                                <option value="max">max</option>
                                <option value="paramount_plus_showtime">paramount_plus_showtime</option>
                                <option value="starz">starz</option>
                                <option value="cinemax">cinemax</option>
                                <option value="mgm_plus">mgm_plus</option>
                                <option value="hallmark">hallmark</option>
                                <option value="hbo">hbo</option>
                                <option value="amc_plus">amc_plus</option>
                                <option value="hulu">hulu</option>
                            </select>
                            {errors.provider && <p className="text-red-600 text-sm">{errors.provider}</p>}
                        </div>
                        <div className="mb-2.5">
                            <label className="block text-gray-900 font-medium text-sm mb-2">Library Names(comma separated)</label>
                            <input
                                type="text"
                                placeholder="Library Names (comma separated)"
                                className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                // format the array to string with comma separated values with space after comma
                                value={formData.library_names.join(', ')}
                                name="library_names"
                                onChange={handleListInputChange}
                            />
                            {errors.library_names && <p className="text-red-600 text-sm">{errors.library_names}</p>}
                        </div>
                        <div className="mb-2.5">
                            <label className="block text-gray-900 font-medium text-sm mb-2">Is Series</label>
                            <ToggleSwitch name="is_series" value={formData.is_series} handleChange={handleChangeCheckbox} />
                        </div>
                        {formData.is_series && (
                            <>
                                <div className="mb-2.5">
                                    <label className="block text-gray-900 font-medium text-sm mb-2">Season Number</label>
                                    <input
                                        type="number"
                                        placeholder="1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                        value={formData.season_number}
                                        onChange={handleChange}
                                        name="season_number"
                                    />
                                    {errors.season_number && <p className="text-red-600 text-sm">{errors.season_number}</p>}
                                </div>
                                <div className="mb-2.5">
                                    <label className="block text-gray-900 font-medium text-sm mb-2">Episode Number</label>
                                    <input
                                        type="number"
                                        placeholder="1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm px-4 py-3 bg-gray-50"
                                        value={formData.episode_number}
                                        onChange={handleChange}
                                        name="episode_number"
                                    />
                                    {errors.episode_number && <p className="text-red-600 text-sm">{errors.episode_number}</p>}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex items-center justify-end pt-4 border-t">
                        <button type="submit" disabled={loading} className="bg-primary1 text-white py-2 px-3 rounded-lg">Add</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SideFilter;
