import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie } from "./utils";

const baseURL = process.env.REACT_APP_BASE_URL;

export const getPriorityList = createAsyncThunk(
    "/api/v2/requests/priority/order",
    async (thunkAPI) => {
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/priority/order`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`,  // Include the token in the headers
                    },
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.log("ERROR", e);
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updatePriorityList = createAsyncThunk(
    "/api/v2/requests/priority-order",
    async ({updatedItems},thunkAPI) => {
        try {
            const response = await fetch(
                `${baseURL}/api/v2/requests/priority-order`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`,  // Include the token in the headers
                    },
                    body: JSON.stringify(updatedItems),
                }
            );
            let data = await response.json();

            if (response.status === 201) {
                return data;
            } else if(response.status === 400) {
                return thunkAPI.rejectWithValue({ error: 'Bad Request' });
            } 
            else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.log("ERROR", e);
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const prioritySlice = createSlice({
    name: "priority",
    initialState: {
        isPriorityFetching: false,
        isPrioritySuccess: false,
        isPriorityError: false,

        setIsPriorityFetching: false,
        setIsPrioritySuccess: false,
        setIsPriorityError: false,

        priorData: [],
        ReqErrorMessage: ''
    },
    reducers: {
        clearState: (state) => {
            state.isPriorityFetching = false
            state.isPrioritySuccess = false
            state.isPriorityError = false

            state.setIsPriorityFetching = false
            state.setIsPrioritySuccess = false
            state.setIsPriorityError = false

            return state;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getPriorityList.pending, (state) => {
            state.isPriorityFetching = true
        });

        builder.addCase(getPriorityList.fulfilled, (state, { payload }) => {
            state.isPriorityFetching = false
            state.isPrioritySuccess = true
            state.priorData = payload
            console.log('priorityData:', payload)
        });

        builder.addCase(getPriorityList.rejected, (state, { payload }) => {
            state.isPriorityError = false
            state.isPriorityFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

        builder.addCase(updatePriorityList.pending, (state) => {
            state.setIsPriorityFetching = true
        });

        builder.addCase(updatePriorityList.fulfilled, (state, { payload }) => {
            state.setIsPriorityFetching = false
            state.setIsPrioritySuccess = true
            state.priorData = payload
            console.log('priorityData:', payload)
        });

        builder.addCase(updatePriorityList.rejected, (state, { payload }) => {
            state.setIsPriorityError = false
            state.setIsPriorityFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
        });

    },
});

export const { clearState } = prioritySlice.actions;

export const priorityData = (state) => state.priorityData;