import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie } from "./utils";

const baseURL = process.env.REACT_APP_BASE_URL;

export const getBulkStatus = createAsyncThunk(
    "/api/v2/requests/bulk-status",
    async (formData, thunkAPI) => {  // Accept form data and token
        try {
            console.log('hey', formData)
            const response = await fetch(
                `${baseURL}/api/v2/requests/bulk-status`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie("token")}`,  // Include the token in the headers
                    },
                    body: JSON.stringify(formData),  
                }
            );
            console.log(response)
            let data = await response.json();
            console.log(data)
            // Handle different response statuses
            if (response.status === 200) {  // Successful creation
                return data;
            } else if (response.status === 400) {  // Bad request
                return thunkAPI.rejectWithValue({
                    status: 400,
                    message: "Bad Request: The server could not understand the request due to invalid syntax.",
                    data
                });
            }
        } catch (e) {
            console.log("ERROR", e);
            return thunkAPI.rejectWithValue({
                status: e.response?.status || 500,
                message: e.response?.data?.message || e.message,
            });
        }
    }
);

export const bulkStatusSlice = createSlice({
    name: "BulkRequests",
    initialState: {
        isBulkReqFetching: false,
        isBulkReqSuccess: false,
        isBulkReqError: false,
        ReqErrorMessage: '',
        requests: [],
        bulkData: null,
        pagination_metadata: null,
        success: ''
    },
    reducers: {
        clearState: (state) => {
            state.isBulkReqFetching = false
            state.isBulkReqSuccess = false
            state.isBulkReqError = false
            state.ReqErrorMessage = ''
            return state;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getBulkStatus.pending, (state) => {
            state.isBulkReqFetching = true
        });

        builder.addCase(getBulkStatus.fulfilled, (state, { payload }) => {
            state.isBulkReqFetching = false
            state.isBulkReqSuccess = true
            state.bulkData = payload?.requests;
            state.pagination_metadata = payload?.pagination_metadata;
            state.success = payload?.status ?? "Success!";
            console.log('payload:',payload)
        });

        builder.addCase(getBulkStatus.rejected, (state, { payload }) => {
            state.isBulkReqError = false
            state.isBulkReqFetching = false
            state.ReqErrorMessage = payload?.message ?? "Something went wrong!";
            console.log(payload)
        });

    },
});

export const { clearState } = bulkStatusSlice.actions;

export const bulkReqData = (state) => state.bulkReqData;