import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Auth";
import { Header } from "./Components/Header/Header";
import { MainFooter } from "./Components/Footer/MainFooter";
import Auth from "./Pages/Login/Auth";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { Sidemenu } from "./Components/Sidemenu/Sidemenu";
import DetailPage from "./Pages/DetailPage/DetailPage";
import Priority from "./Pages/Priority/Priority";
import { Provider } from "react-redux";
import store from './reducer/store/store'
import { useEffect, useState } from "react";

const ProtectedRoute = (props) => {
  const { component: Component } = props;
  const [token, setToken] = useState(null);

  function getCookie(key) {
    const cookie = document.cookie.split("; ").find((row) => row.startsWith(key));

    return cookie?.split("=")[1];
  }

  useEffect(() => {
    const token = getCookie('token');

    if (!token) {
      window.location.href = '/';
    }
    else { 
      setToken(token); 
    }

  }, [getCookie('token')]);

  return (
    <>
      {
        token ? <Component /> : <></>
      }
    </>
  );
};

function App() {

  const { pathname } = useLocation();

  return (
    <Provider store={store}>
      <div className={`w-full ${pathname !== '/' ? 'flex justify-start items-start flex-wrap' : `flex flex-col h-screen`} `}>
        {/* {pathname === '/' ? <Header /> : undefined} */}
        {pathname !== '/' ? <Sidemenu style={'max-width: 256px'} /> : undefined}
        <div className={`w-full ${pathname !== '/' ? `px-8 py-8 bg-newgrey h-screen overflow-auto` : `flex-grow`}`} style={pathname !== '/' ? { maxWidth: 'calc(100% - 256px)' } : null}>
          <Routes>
            <Route path="/" exact element={<Auth />} />
            <Route path="/dashboard" exact element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/detail/:id" exact element={<ProtectedRoute component={DetailPage} />} />
            <Route path="/priority" exact element={<ProtectedRoute component={Priority} />} />
          </Routes>
        </div>
        {pathname === '/' ? <MainFooter /> : undefined}
      </div>
    </Provider>
  );
}

export default App;
